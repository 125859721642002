<template>
  <div class="row">
    <div class="col-md-12">
      <dashboard-box
        :back="true"
        back-component-name="management.fabric-permission.index"
      >
        <template v-slot:title>
          <span>{{
            $t(
              id != null
                ? "fabric_permission.edit_fabric_permission"
                : "fabric_permission.create_fabric_permission"
            )
          }}</span>
        </template>
        <template v-slot:preview>
          <div class="row d-flex">
            <div class="col-xl-4">
              <custom-multi-select
                :helperText="$t('general.please_select')"
                :model.sync="fabric_id"
                :options="fabricOptions"
                :is-inline="true"
                :title="$t('fabric.fabrics') + ':'"
                class="font-weight-bolder"
                input-max-width="100%"
                input-width="100%"
                width-class="100%"
              />
            </div>
            <div class="col-xl-4">
              <custom-multi-select
                :helperText="$t('general.please_select')"
                :title="$t('scope.Role') + ':'"
                :model.sync="role_id"
                name="ecpense_category"
                :options="roleOptions"
                :not-list="true"
                :is-inline="true"
                :max="1"
                :item-per-row="1"
                class="font-weight-bolder"
                input-max-width="100%"
                input-width="100%"
                width-class="100%"
              ></custom-multi-select>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-5">
            <button-with-icon
              class="mr-3 danger-border-1px"
              @onClick="$router.go(-1)"
              :text="$t('general.cancel')"
              size="lg"
              :icon-name="null"
            ></button-with-icon>
            <button-with-icon
              @onClick="createOrUpdatefabric"
              :text="$t(id != null ? 'general.save' : 'general.create')"
              size="lg"
              :icon-name="null"
              :disabled="!isValidToCreate"
            ></button-with-icon>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from "vuex";
import {
  CREATE_ITEM,
  CURRENT_ITEM,
  ERROR,
  GET_ITEMS,
  ITEMS,
  GET_ITEM_DETAIL_BY_ID,
  LOADING,
  SET_CURRENT_ITEM,
  SUCCESS,
  UPDATE_ITEM_BY_ID,
} from "@/core/services/store/panel-managment/crud/panel.management.module";

import TextInput from "@/assets/components/inputs/TextInput";
import SelectInput from "@/assets/components/inputs/SelectInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import { LANGUAGE } from "@/core/services/store/system-options.module";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";

export default {
  name: "fabricCreateEdit",
  components: {
    ButtonWithIcon,
    TextInput,
    SelectInput,
    CustomMultiSelect,
    DashboardBox,
  },
  data() {
    return {
      id: null,
      roles: [],
      models: [],
      fabric_id: [],
      fabric_type: [],
      role_id: null,
    };
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
      success: SUCCESS,
      language: LANGUAGE,
    }),
    isValidToCreate() {
      if (this.fabric_id == null || this.role_id == null) return false;

      return true;
    },
    fabricOptions() {
      let data = this.models;
      return this.convertArrayToObjectByKey(data, "id", "name");
    },
    roleOptions() {
      let data = this.roles;
      return this.convertArrayToObjectByKey(data, "id", "name");
    },
  },

  methods: {
    ...mapMutations({
      setfabric: SET_CURRENT_ITEM,
    }),
    getModelData() {
      let payload = {
        url: "api/all-fabric-models",
        returnType: "stateless",
      };

      this.$store.dispatch(GET_ITEMS, payload).then((response) => {
        if (response.status) {
          this.models = response.data;
        }
      });
    },
    getRolesData() {
      let payload = {
        url: "api/roles",
        returnType: "stateless",
      };

      this.$store.dispatch(GET_ITEMS, payload).then((response) => {
        if (response.status) {
          this.roles = response.data;
        }
      });
    },
    createOrUpdatefabric() {
      let formatedFabricModels = [];
      let formatedFabricModelName = [];
      this.fabric_id.forEach((fabric) => {
        formatedFabricModels.push(+fabric);
        let findItem = _.find(this.models, { id: +fabric });
        formatedFabricModelName.push(findItem.class_name);
      });
      let payload = {
        url:
          this.id == null
            ? "api/fabric-permission"
            : "api/fabric-permission/" + this.id,
        id: this.id,
        contents: {
          fabric_id: formatedFabricModels,
          fabric_models: formatedFabricModelName,
          role_id: +this.role_id,
        },
        redirectRouteName: "management.fabric-permission.index",
        successMessage: this.$t(
          this.id == null
            ? "general.successfully_created"
            : "general.successfully_updated"
        ),
      };
      this.$store.dispatch(this.id == null ? CREATE_ITEM : UPDATE_ITEM_BY_ID, payload);
    },
  },
  mounted() {
    this.id = this.$route.params.id;

    if (this.id != null) {
      let payload = {
        url: "api/fabric-permission/" + this.id,
        id: this.id,
        redirectRouteOnNotFound: "management.fabric-permission.index",
      };

      this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, payload).then((response) => {
        let data = response.data;
        this.role_id = data.role_id;
        this.fabric_id = data.all_fabric_id.map((fabric) => {
          return String(fabric);
        });
      });
    }

    this.getModelData();
    this.getRolesData();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      {
        title: this.$t("fabric_permission.fabric_permission"),
        route: "management.fabric-permission.index",
      },
      {
        title: this.$t(
          this.id != null
            ? "fabric_permission.edit_fabric_permission"
            : "fabric_permission.create_fabric_permission"
        ),
      },
    ]);
  },
};
</script>

<style scoped>
#dashboard-box {
  padding: 10px;
}
</style>
